const swiperProducts = new Swiper(".js-horScroll-vacancies", {
    slidesPerView: "5",
    spaceBetween: 70,
    navigation: {
        nextEl: ".vacancies__btn.swiper-button-next",
        prevEl: ".vacancies__btn.swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: '1',
            spaceBetween: 20,
        },
        767: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        1279: {
            slidesPerView: 5,
            spaceBetween: 70,
        },
    },
});