const swiperProducts = new Swiper(".js-horScroll-navy", {
    slidesPerView: "3",
    spaceBetween: 150,
    navigation: {
        nextEl: ".navy__btn.swiper-button-next",
        prevEl: ".navy__btn.swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: '1',
            spaceBetween: 20,
        },
        767: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        1279: {
            slidesPerView: 3,
            spaceBetween: 150,
        },
    },
});